
.card-shadow{
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}

.drawer-shadow{
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.5);
}

.header-drawer{
  background: rgb(40,83,255);
  background: linear-gradient(180deg, rgba(40,83,255,1) 30%, rgba(30,0,142,1) 100%);
}

.form_search{
  height: 100%;
}

/* Ancho de la barra de desplazamiento */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 6px;
}

/* Colores de la barra de desplazamiento y su pulgar */
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

/* Estilos de la barra de desplazamiento cuando el cursor está sobre el contenedor */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(49, 83, 255, 0.9);
  border-radius: 6px;
}
:hover::-webkit-scrollbar-thumb {
  background-color: rgba(49, 83, 255, 0.9);
  border-radius: 6px;
}
:hover::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;
}

.input-file{
  background-color: aqua;
  display: none;
}

.input-file-label{
  display: flex;
  box-sizing: border-box;
  color: #fff;
  background-color: #3153ffe6;
  cursor: pointer;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 230px;
  min-height: 35px;
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}

.input-file-label:active{
  background-color:  #002affe6;
}

.image_logo{
  height: 100px;
}

.item_hover:hover{
  background-color: rgba(0, 0, 0, 0.04);
}